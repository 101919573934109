.selected {
    text-align: center;
    border: white 5px solid;
    color: white;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.segment {
    text-align: center;
    color: white;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .3s;
}

.segment:hover {
    text-align: center;
    border: white 5px solid;
}