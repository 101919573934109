.shapeMenu {
	display: inline-flex;
    flex-direction: column;
    position: absolute;
    padding: 1rem;
    border-radius: 20px;
	background: linear-gradient( 144deg, rgba(151,111,251,1) 0%, rgba(188,0,255,1) 100% );
	color: white;
}

.shapeMenu > div {
	display: flex;
    justify-content: space-between;
}

.shapeMenu > div label {
	padding-right: 2rem;
}


.shapeMenu input[type=number] {
	width: 80px;
	text-align: end;
	border: solid 1px black;
}

#copyNremove {
	margin-top: 1rem;
    display: flex;
    justify-content: center;
}

/* .shapeMenu::after {
	content: '';
	position: relative;
	bottom: -35px;
	width: 0px;
	height: 0px;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #dedede;
} */