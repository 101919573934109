@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, header p {
  font-style: italic;
  font-weight: 600;
}

header a {
  margin: 1rem 0;
  font-style: italic;
  font-weight: 600;
  text-decoration: none;
  transition: color .3s;
}

header a:hover {
  color: black
}

button {
  background: white;
  border: solid 1px black;
  padding: 2px 10px;
  transform: skew(0, -6deg);
  pointer-events: all;
}
.animate{
    transition: all .1s;
}

.animate:hover {
    stroke-width: 4px;
    fill: rgba(151, 111, 251, 1);
    stroke: rgba(188, 0, 255, 1);
    opacity: 1;
}
.shapeMenu {
	display: inline-flex;
    flex-direction: column;
    position: absolute;
    padding: 1rem;
    border-radius: 20px;
	background: linear-gradient( 144deg, rgba(151,111,251,1) 0%, rgba(188,0,255,1) 100% );
	color: white;
}

.shapeMenu > div {
	display: flex;
    justify-content: space-between;
}

.shapeMenu > div label {
	padding-right: 2rem;
}


.shapeMenu input[type=number] {
	width: 80px;
	text-align: end;
	border: solid 1px black;
}

#copyNremove {
	margin-top: 1rem;
    display: flex;
    justify-content: center;
}

/* .shapeMenu::after {
	content: '';
	position: relative;
	bottom: -35px;
	width: 0px;
	height: 0px;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #dedede;
} */
.selected {
    text-align: center;
    border: white 5px solid;
    color: white;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.segment {
    text-align: center;
    color: white;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all .3s;
}

.segment:hover {
    text-align: center;
    border: white 5px solid;
}
