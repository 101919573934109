@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,200&display=swap');

*{
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, header p {
  font-style: italic;
  font-weight: 600;
}

header a {
  margin: 1rem 0;
  font-style: italic;
  font-weight: 600;
  text-decoration: none;
  transition: color .3s;
}

header a:hover {
  color: black
}

button {
  background: white;
  border: solid 1px black;
  padding: 2px 10px;
  transform: skew(0, -6deg);
  pointer-events: all;
}